import React, {useEffect, useState} from "react";

import {get} from "../../http/http";
import Category from "../../model/Category";
import OwlCarousel from "react-owl-carousel";
import {RouteComponentProps, withRouter} from "react-router";
import PointOfSale from "../../model/PointOfSale";
import {Artist, parsePointOfSaleToArtist} from "../../model/Artist";
import Image from "../Image/Image";
import config from "../../config/config.json";

type AllArtistAndGenresBarProps = RouteComponentProps;
function ArtistAndGenresBar(props: AllArtistAndGenresBarProps) {

    const borders = ["lightBlueMovistar"];

    const [artists, setArtists] = useState<Artist[]>([]);
    const [categories, setCategories] = useState<Category[]>([]);

    useEffect(() => {
        let artistsPromise = get(`/pointofsale/${config.apiKey}`).then(
            (res: { name: string; menus: PointOfSale[] }) => {
                res.menus.forEach((menu: PointOfSale) => {
                    if (menu.name === "ARTISTA DEL MES") {
                        setArtists(parsePointOfSaleToArtist(menu));
                    }
                });
            }
        );

        let categoriesPromise = get(`/categories/${config.apiKey}`).then(
            (cats: Category[]) => {
                setCategories(cats);
            }
        );

        Promise.allSettled([artistsPromise, categoriesPromise]).then(() => {
            const aux = document.getElementById("defaultOpen");
            if (aux) aux.click();
        });
    }, []);

    const openCity = (evt: any, cityName: any) => {
        var i, tablinks;
        var tabcontent: HTMLCollectionOf<Element> = document.getElementsByClassName("tabcontent");
        for (i = 0; i < tabcontent.length; i++) {
            let aux = tabcontent[i];
            if(aux) aux.setAttribute("style", "display: none")
        }
        tablinks = document.getElementsByClassName("tablinks");
        for (i = 0; i < tablinks.length; i++) {
            tablinks[i].className = tablinks[i].className.replace(" active", "");
        }
        var city = document.getElementById(cityName);
        if(city && city.style) city.style.display = "block";
        evt.currentTarget.className += " active";
    };

    const handleCategoryClick = (e: any, category: Category) => {
        e.preventDefault();
        props.history.push(`/category/${category.categoryID}/${category.categoryName}`);
    };

    const handleArtistClick = (e: any, artist: Artist) => {
        e.preventDefault();
        props.history.push(`/artist/${artist.albumId}/${artist.toneArtist}`, {albumExtension: artist.albumExtension});
    };

    return (
        <div className="row">
            <div className="TabCont">
                <div className="tab">
                <button className="tablinks"  onClick={(e) => openCity(e, "Artistas")}>
                        <b>Artistas</b>
                        <span>TOP</span>
                    </button>
                <button className="tablinks" id="defaultOpen" onClick={(e) => openCity(e, "Categorias")}>
                        Categorías
                    </button> 
                </div>
            </div>

                {
                    artists.length > 0 &&
                    <div className="carousel-wrap" style={{maxWidth: 1440}}>

                    <OwlCarousel
                        id="Artistas"
                        className="tabcontent"
                        dotsClass="owl-dots"
                        loop={false}
                        dots={true}
                        margin={5}
                        autoPlay={false}
                        autoplayHoverPause={true}
                        responsive={
                            {
                                0: {
                                    items: 3
                                },
                                400: {
                                    items: 4
                                },
                                600: {
                                    items: 6
                                },
                                800: {
                                    items: 8
                                },
                                1000: {
                                    items: 11
                                },
                                1400: {
                                    items: 12
                                },
                                1600: {
                                    items: 13
                                },
                                1800: {
                                    items: 14
                                }
                            }
                        }
                    >
                        {
                            artists.length > 0 && artists.map((artist: Artist, index) => (
                                <div className="item" key={`artist_${index}`}>
                                    <a href="/" onClick={(e) => handleArtistClick(e, artist)}>
                                        <div className={`borderCircle ${borders[Math.floor(Math.random() * borders.length)]}`}/>
                                            <Image id={artist.albumId}
                                                   extension={artist.albumExtension}
                                                   url={config.imageUrl}/>
                                             <div className="labelMen">{artist.toneArtist}</div>
                                    </a>
                                </div>
                            ))
                        }
                    </OwlCarousel>
                    </div>
                }

                {
                    categories.length > 0 &&
                    <div className="carousel-wrap" style={{maxWidth: 1440}}>

                    <OwlCarousel
                        id="Categorias"
                        className="tabcontent"
                        dotsClass="owl-dots"
                        loop={false}
                        dots={true}
                        margin={5}
                        autoPlay={false}
                        autoplayHoverPause={true}
                        responsive={
                            {
                                0: {
                                    items: 3
                                },
                                400: {
                                    items: 4
                                },
                                600: {
                                    items: 6
                                },
                                800: {
                                    items: 8
                                },
                                1000: {
                                    items: 11
                                },
                                1400: {
                                    items: 12
                                },
                                1600: {
                                    items: 13
                                },
                                1800: {
                                    items: 14
                                }
                            }
                        }>
                        {
                            categories.map((cat, index) => (
                                <div className="item" key={`category_${index}`}>
                                    <a href={`/`} onClick={(e) => handleCategoryClick(e, cat)}>
                                        <div
                                            className={`borderCircle ${borders[Math.floor(Math.random() * borders.length)]}`}/>
                                            <Image disablePathUtil id={cat.categoryID} url={config.categoriesImagesUrl}/>
                                        <div className="labelMen">{cat.categoryName}</div>
                                    </a>
                                </div>
                            ))
                        }
                    </OwlCarousel>
                    </div>
                }
        </div>
    )
}

export default withRouter(ArtistAndGenresBar);
