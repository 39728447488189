import React, {useEffect, useState} from "react";
import {RouteComponentProps, withRouter} from "react-router";
import {get} from "../http/http";
import Carousel from "../common/Carousel/Carousel";
import ToneInfo, {parseSearchedTones, SearchTone} from "../model/ToneInfo";
import config from "../config/config.json";

interface SearchPageRouterParams {
    searchString: string;
}
type AllSearchPageProps = RouteComponentProps<SearchPageRouterParams>
function SearchPage(props: AllSearchPageProps) {

    const [searchedTones, setSearchedTones] = useState<ToneInfo[]>([]);

    useEffect( () => {

        get(`/search/${props.match.params.searchString}/${config.apiKey}`)
            .then((res: SearchTone[]) => {
               setSearchedTones(parseSearchedTones(res))
            });
        return () => {
            setSearchedTones([]);
        }
    }, [props.match.params]);

    return (
        <div className={"row"}>
            <Carousel tones={searchedTones}
                      removeCarousel
                      title={<span><b>RESULTADO</b> DE LA BUSQUEDA - <b>{`${props.match.params.searchString.toUpperCase()}`}</b></span>}/>
        </div>
    )
}

export default withRouter(SearchPage)
