import PointOfSale from "./PointOfSale";

export default interface ToneInfo {
    toneId: number;
    toneName: string;
    toneArtist: string;
    toneAlbum: string;
    playing: boolean;
    albumExtension: string;
    days?: number;
    purchasePrice?: number;
    renewPrice?: number;
}

export interface SearchTone {
    toneId: number;
    toneName: string;
    albumId: number;
    albumExtension: string;
    artist: {artistId: number, artistName: string};
    category: {categoryId: number, categoryName: string};
}

export function parseSearchedTones(searchedTones: SearchTone[]): ToneInfo[] {
    let result: ToneInfo[] = [];
    searchedTones.forEach((st) => {
        result.push(
            {
                toneId: st.toneId,
                toneName: st.toneName,
                toneArtist: st.artist.artistName,
                albumExtension: st.albumExtension,
                toneAlbum: st.albumId.toString(),
                playing: false
            }
        )
    });
    return result;
}
export function parsePointOfSale(ps: PointOfSale): ToneInfo[] {
    let result: ToneInfo[] = [];

    ps.playList?.forEach((opt) => {
        result.push({
            toneId: opt.toneId,
            toneName: opt.toneName,
            toneArtist: opt.toneArtist,
            albumExtension: opt.albumExtension,
            toneAlbum: opt.albumId.toString(),
            playing: false
        })
    });
    return result;
}
