import React, { useContext } from "react";
import { post} from "../../http/http";
import UserContext from "../../context/UserContext";
import { Transaction } from "../../model/Transaction";
import AlertContext from "../../context/AlertContext";

export default function RegisterModal() {
  const [msisdn, changeMsisdn] = React.useState<string>("");

  const user = useContext(UserContext);
  const alert = useContext(AlertContext);

  const closeModal = () => {
    $("#modal8").removeClass("opened");
  };

  const handleMsisdnChange = (e: any) => {
    changeMsisdn(e.target.value);
  };

  const handlePinObtain = () => {
          post(`/sendPin/${msisdn}`)
            .then((res: Transaction & { msisdn: number; isMovistar :boolean }) => {
              if(!res.isMovistar) {
                alert.setMessageAndTitle(
                  "Compruebe que el numero pertenezca a Movistar",
                  "ERROR"
                );
              }else if (res.transactionResponse.isTransactionOk) {
                user.setPinMsisdn(res.msisdn.toString());
                $("#modal8").removeClass("opened");
                $("#modal2").addClass("opened");
              } else {
                if (res.transactionResponse.responseCode === 9028) {
                  alert.setMessageAndTitle("El número es incorrecto", "ERROR");
                } else {
                  alert.setMessageAndTitle(
                    "Ocurrió un error al enviar el pin",
                    "ERROR"
                  );
                }
              }
            })
            .catch(() =>
              alert.setMessageAndTitle(
                "Ocurrió un error al enviar el pin",
                "ERROR"
              )
            );
  };

  return (
    <div id="modal8" className="modale" aria-hidden="true">
      <div className="modal-dialog">
        <div className="modal-header">
          <h2>Inicio de Sesión  - Paso 1</h2>
          <button
            className="btn-close closemodale8"
            aria-hidden="true"
            onClick={closeModal}
          >
            &times;
          </button>
        </div>
        <div className="modal-body">
          <div className="inputCont input-effect">
            <input
              className="effect-20"
              type="text"
              placeholder=""
              value={msisdn}
              onChange={handleMsisdnChange}
            />
            <label>Teléfono</label>
            <span className="focus-border">
              <i />
            </span>
          </div>
          <p>
            Llena el campo y confirma en "OBTENER PIN" y enviaremos un PIN por
            SMS.
          </p>
        </div>
        <div className="modal-footer">
          <button
            disabled={msisdn === ""}
            className="btn openmodale2"
            id="btn_ingresar"
            onClick={handlePinObtain}
          >
            OBTENER PIN
          </button>
          <button className="btn2" id="btn_volver" onClick={closeModal}>
            VOLVER
          </button>
        </div>
      </div>
    </div>
  );
}
