import React from "react";
import ToneInfo from "../../model/ToneInfo";
import Slider, { Settings } from "react-slick";
import { RouteComponentProps, withRouter } from "react-router";
import Image from "../Image/Image";
import config from "../../config/config.json";

interface CarouselProps {
  title: any;
  tones: ToneInfo[];
  customKey?: string;
  removeCarousel?: boolean;
}

type AllCarouselProps = CarouselProps & RouteComponentProps;

/**
 *
 * @param title
 * @param tones
 * @param history
 * @param location
 * @param customKey
 * @param removeCarousel
 * @constructor
 *
 *
 * In this component the carousel is wrapped in a <div> with a key in order to reload component when
 * user wants to go from a category to another (Carousel is destroyed and then re created)
 */
function Carousel({
  title,
  tones,
  history,
  location,
  customKey,
  removeCarousel,
}: AllCarouselProps) {
  let rows;
  if (tones.length > 48) {
    rows = 6;
  } else {
    rows = removeCarousel ? Math.ceil(tones.length / 6) : 1;
  }

  const settings = {
    arrows: tones.length > 48 ? true : !removeCarousel,
    dots: true,
    dotsClass: "slick-dots",
    speed: 500,
    accessibility: false,
    adaptiveHeight: false,
    slidesPerRow: 6,
    rows: rows,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesPerRow: 5,
          rows: removeCarousel ? Math.ceil(tones.length / 5) : 1,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesPerRow: 4,
          rows: removeCarousel ? Math.ceil(tones.length / 4) : 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesPerRow: 2,
          rows: removeCarousel ? Math.ceil(tones.length / 2) : 1,
        },
      },
    ],
  } as Settings;

  return (
    <>
      <div className="row">
        <div className="titleCont">
          <div className="titlePpal">{title}</div>
        </div>
      </div>
      {
        <div key={customKey ? customKey : location.pathname}>
          {tones.length > 0 && (
            <Slider {...settings} className="responsive">
              {tones.map((tone) => (
                <div
                  key={`tone_${tone.toneId}`}
                  onClick={(e) => {
                    e.preventDefault();
                    history.push(`/tone/${tone.toneId}`, {
                      key: config.apiKey,
                    });
                  }}
                >
                  <div className="container">
                    <Image
                      id={tone.toneAlbum}
                      extension={tone.albumExtension}
                      url={config.imageUrl}
                    />
                    <div className="middle">
                      <div className="play" />
                    </div>
                  </div>
                  <p className="TextDesc1" title={tone.toneName}>
                    {`${
                      tone.toneName.length > 15
                        ? `${tone.toneName.substring(0, 14)}...`
                        : tone.toneName
                    }`}
                  </p>
                  <p className="TextDesc2" title={tone.toneArtist}>
                    {`${
                      tone.toneArtist.length > 15
                        ? `${tone.toneArtist.substring(0, 14)}...`
                        : tone.toneArtist
                    }`}
                  </p>
                </div>
              ))}
            </Slider>
          )}
        </div>
      }
    </>
  );
}

export default withRouter(Carousel);
