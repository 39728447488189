import React, {useContext, useState} from "react";
import UserContext from "../../context/UserContext";
import ToneContext from "../../context/ToneContext";
import {deleteRequest, get} from "../../http/http";
import {RouteComponentProps, withRouter} from "react-router";
import AlertContext from "../../context/AlertContext";
import config from "../../config/config.json";

type ToneDeleteModalProps = RouteComponentProps;

function ToneDeleteModal(props: ToneDeleteModalProps) {

    const closeModal = () => {
        $('#modal3').removeClass('opened');
    };

    const toneContext = useContext(ToneContext);
    const user = useContext(UserContext);
    const alert = useContext(AlertContext);
    const token = sessionStorage.getItem("token");
    const [deleteTone, setDeleteTone] = useState<boolean>(false);

    const cancelTone = () => {
        const headers = {
            'authorization': `${token}`,
        };        
            setDeleteTone(true)
            deleteRequest(`/cancelTone/${user.user.msisdn}/${toneContext.tone.toneId}`, {key:config.apiKey}, headers)
            .then(() => {
                const headers = {
                    'authorization': `${token}`,
                };                           
                get(`/getUserTonesAndServices/${user.user.msisdn}/${config.apiKey}`, undefined, headers) //key: config.baseUrl
                    .then((tonesAndServicesResponse) => {
                        user.setUser({...user.user,
                            userTones: tonesAndServicesResponse.userTones,
                            userServices: tonesAndServicesResponse.userServices
                        });
                        $('#modal3').removeClass('opened');
                        setDeleteTone(false)                  
                    })
                    .catch(() => {
                        alert.setMessageAndTitle("Ocurrió un error al borrar el tono", "ERROR");
                        setDeleteTone(false)                     
                    });
            })
            .catch(() => {
                alert.setMessageAndTitle("Ocurrió un error al borrar el tono", "ERROR");
                setDeleteTone(false)                
            })                                           
    };

    return (
        <div id="modal3" className="modale" aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-header">
                    <h2>Eliminar</h2>
                    <button className="btn-close closemodale3" aria-hidden="true" onClick={closeModal}>&times;</button>
                </div>
                <div className="modal-body">
                    <div className="inputCont">
                        <h2>{`Tono ${toneContext.tone.toneName}`}</h2>
                        <p>¿Deseas eliminar el tono seleccionado?</p>
                    </div>
                </div>

                <div className="modal-footer">
                    <button className="btn" id="btn_ingresar" disabled={deleteTone} onClick={cancelTone}>ELIMINAR</button>
                    <button className="btn2" id="btn_volver"  onClick={closeModal}>CANCELAR</button>
                </div>
            </div>
        </div>
    )
}

export default withRouter(ToneDeleteModal)
