import React, {useEffect} from "react";
import {get, post} from "../../../http/http";
import UserContext from "../../../context/UserContext";
import ToneInfo from "../../../model/ToneInfo";
import ToneConfigurationCallers from "./ToneConfigurationCallers";
import {TransactionResponse} from "../../../model/Transaction";
import AlertContext from "../../../context/AlertContext";
import "./ToneConfigurationModal.scss";
import config from "../../../config/config.json";

interface ToneConfigurationModalProps {
    tone: ToneInfo;
    open: boolean;
    setOpenConfigModal(val: boolean): void
}

export default function ToneConfigurationModal({tone, open, setOpenConfigModal}: ToneConfigurationModalProps) {

    const user = React.useContext(UserContext);
    const alert = React.useContext(AlertContext);
    const token = sessionStorage.getItem("token");
    const [reproductionType, setType] = React.useState<string>("");
    const [callers, setCallers] = React.useState<number[]>([]);
    const [addedNumbers, setAddedNumber] = React.useState<number>(0); // Used to recall useEffect when a number is added
    const [initialValues, setInitialValues] = React.useState<{ callers: number[], reproductionType: string }>({
        callers: [],
        reproductionType: ""
    });

    const getReproductionData = () => {
        const headers = {
            'authorization': `${token}`,
        };
        get(`/reproductionData/${user.user.msisdn}/${tone.toneId}/${config.apiKey}`, undefined, headers) // key:config.baseUrl
            .then((res: { callers: number[], reproductionType: string }) => {
                setCallers(res.callers);
                setType(res.reproductionType);
                setInitialValues({callers: res.callers, reproductionType: res.reproductionType});
            })
    };

    useEffect(() => {
        if(open) {
            getReproductionData();
        }
    }, [tone.toneId, open, addedNumbers]);


    const closeModal = () => {
        setOpenConfigModal(false);
        $('#modal5').removeClass('opened');
    };

    const handleTypeChange = (e: any) => {
        const selectedType = e.target.value;
        if(initialValues.reproductionType !== selectedType) {
            setCallers([])
        } else {
            setCallers(initialValues.callers);
        }
        setType(selectedType);
    };

    const handleConfirm = () => {
        const headers = {
            'authorization': `${token}`,
        };
        post("/callerAssignment", {
            msisdn: user.user.msisdn,
            reproductionType: "All",
            callers: [],
            toneId: tone.toneId,
            operation: "Add",
            key:config.apiKey
        },
        headers)
            .then((res: TransactionResponse) => {
                if(res.isTransactionOk){
                    alert.setMessageAndTitle(`El tono fue configurado para ser escuchado en todas las llamadas`, "Configuración exitosa");
                } else throw new Error("Transaction not ok")
            })
            .catch(() => {
                alert.setMessageAndTitle(`Ocurrió un error al configurar el número`, "ERROR");
            })
    };

    return (
        <div id="modal5" className="modale ToneConfigurationModal" aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-header">
                    <h2>Configuración de Llamadas</h2>
                    <button className="btn-close closemodale5" aria-hidden="true" onClick={closeModal}>&times;</button>
                </div>
                <div className="modal-body">
                    <div className="inputCont">
                        <div className="row">
                            <label>
                                <input type="radio"
                                       id="todas"
                                       name="gender"
                                       value={"AllCalls"}
                                       checked={reproductionType === "AllCalls"}
                                       onChange={handleTypeChange}/>
                                Configurar para que sea escuchado en todas las llamadas.
                            </label>
                        </div>
                        <div className="row">
                            <label>
                                <input type="radio"
                                       id="some"
                                       name="gender"
                                       value={"WhiteList"}
                                       checked={reproductionType === "WhiteList"}
                                       onChange={handleTypeChange}/>
                                Configurar para que sea escuchado solamente por ciertos números.
                            </label>
                        </div>
                    <div className="row">
                        <label>
                            <input type="radio"
                                   id="except"
                                   name="gender"
                                   value={"BlackList"}
                                   checked={reproductionType === "BlackList"}
                                   onChange={handleTypeChange}/>
                            Configurar para que sea escuchado en todas las llamadas, exceptuando ciertos
                                números.
                        </label>
                    </div>
                    {
                            reproductionType === "AllCalls" ?
                                <div className={"confirm-all-calls-wrapper"}>
                                    <button className="btn" id="btn_ingresar" onClick={handleConfirm}>CONFIRMAR</button>
                                </div>
                                :
                                <ToneConfigurationCallers callers={callers}
                                                          changeCallers={setCallers}
                                                          setAddedNumber={setAddedNumber}
                                                          reproductionType={reproductionType}/>
                        }
                    </div>
                </div>

                <div className="modal-footer">
                    <button className="btn2" id="btn_volver" onClick={closeModal}>CANCELAR</button>
                </div>
            </div>
        </div>
    )
}
