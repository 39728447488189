import * as React from "react"
import {useEffect, useRef, useState} from "react";
import ReactHowler from "react-howler";

export interface MyAudioContextState {
    playing: boolean;
    url: string;
    loaded: boolean;
    currentTone?: number;
    setUrl: (url: string) => void;
    setPlaying: (playing: boolean) => void;
    setCurrentTone: (currentTone: number) => void;
}
const initialAudioContextState: MyAudioContextState = {
    playing: false,
    url: "",
    loaded: false,
    currentTone: undefined,
    setUrl: (url: string) => {},
    setPlaying: (playing: boolean) => {},
    setCurrentTone: (currentTone: number) => {},
};

const MyAudioContext = React.createContext(initialAudioContextState);

export const AudioProvider: React.FunctionComponent<any> = ({children}) => {

    const ref = useRef<ReactHowler | null>(null);
    const [audioContextState, updateAudioContextState] = useState(initialAudioContextState);

    initialAudioContextState.setUrl = (url: string) => {
        updateAudioContextState(prevState => {
            return {...prevState, url}
        })
    };

    initialAudioContextState.setPlaying = (playing: boolean) => {
        if(!playing) ref.current?.stop();

        updateAudioContextState(prevState => {
            return {...prevState, playing}
        })
    };

    initialAudioContextState.setCurrentTone = (currentTone: number) => {
        updateAudioContextState(prevState => {
            return {...prevState, currentTone}
        })
    };

    return (
        <MyAudioContext.Provider value={audioContextState}>
            <ReactHowler src={[audioContextState.url]}
                         ref={(audioRef) => ref.current = audioRef}
                         playing={audioContextState.playing}
                         onLoadError={() => {
                             audioContextState.setPlaying(false);
                         }}
                         onLoad={() => {
                             updateAudioContextState(prevState => {
                                 return {...prevState, loaded: true}
                             })
                         }}
                         preload={false}
                         onEnd={() => audioContextState.setPlaying(false)}
            />
            {
                children
            }
        </MyAudioContext.Provider>
    )
};


export default MyAudioContext;
