import axios, {AxiosRequestConfig} from "axios";
import config from "../config/config.json";

const httpClient = axios.create();

const baseHeaders = {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*'
};

const _request = (url: string, method?: string, data?: {}, extraHeaders?: {}) => {

    let headers = {
        ...baseHeaders,
        ...extraHeaders
    }
    return httpClient(
        {
            url: `${config.baseUrl}${url}`,
            method,
            data,
            headers,
        } as AxiosRequestConfig)
        .then((res: { status: number, data: any }) => {
            if(res.status === 200) return res.data;
        })
};


export const get = (url: string, body?:{}, headers?: {}) => _request(url, "GET", undefined, headers);

export const post = (url: string, body?: {}, headers?: {}) => _request(url, "POST", body, headers);

export const put = (url: string, body?: {}, headers?: {}) => _request(url, "PUT", body, headers);

export const deleteRequest = (url: string, body?: {}, headers?: {}) => _request(url, "DELETE", body, headers);


// import axios, {AxiosRequestConfig} from "axios";
// import config from "../config/config.json";

// const httpClient = axios.create();

// const baseHeaders = {
//     'Content-Type': 'application/json',
//     'Access-Control-Allow-Origin': '*'
// };

// const _request = (url: string, method?: string, data?: {}) => {
//     const headers = {...baseHeaders};
    
//     const newUrl = `${config.baseUrl}${url}`
//     let newBody = data
//     if(method === "GET") {
//         newUrl.concat(`/${config.apiKey}`)
//     }else{
//         newBody = {...newBody, apiKey: config.apiKey}
//     }

//     return httpClient(
//         {
//             newUrl,
//             method,
//             data : newBody,
//             headers,
//         } as AxiosRequestConfig)
//         .then((res: { status: number, data: any }) => {
//             if(res.status === 200) return res.data;
//         })
// };


// export const get = (url: string) => _request(url, "GET", undefined);

// export const post = (url: string, body?: {}) => _request(url, "POST", body);

// export const put = (url: string, body?: {}) => _request(url, "PUT", body);

// export const deleteRequest = (url: string, body?: {}) => _request(url, "DELETE", body);
