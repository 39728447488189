import * as React from "react";
import {useEffect, useState} from "react";
import User from "../model/User";
import {get} from "../http/http";
import config from "../config/config.json";

const initialUserContextState = {
    user: {
        msisdn: undefined,
        isValid: false,
        userTones: [],
        userServices: []
    } as User,
    pinMsisdn: "",
    setPinMsisdn:(msisdn: string) => {},
    setUser: (user: User) => {},
    userLogout: () => {}
};

const UserContext = React.createContext(initialUserContextState);

export const UserProvider: React.FunctionComponent<any> = ({children}) => {
    initialUserContextState.setUser = (user: User) => {
        if (user == null) {
            updateUserContextState(prevState => {
                return {...prevState, user: initialUserContextState.user}
            });
        } else {
            updateUserContextState(prevState => {
                return {...prevState, user: {...prevState.user, ...user}}
            });
        }
    };

    initialUserContextState.userLogout = () => {
        initialUserContextState.setUser(initialUserContextState.user);
    };

    initialUserContextState.setPinMsisdn = (msisdn: string) => {
        updateUserContextState((prevState) => {
            return {...prevState, pinMsisdn: msisdn}
        })
    };
    const [userContextState, updateUserContextState] = useState(initialUserContextState);

    useEffect(() => {
        const msisdn = sessionStorage.getItem("msisdn");
        const token = sessionStorage.getItem("token");
        if(msisdn && token) {
                        const headers = {
                            'authorization': `${token}`,
                        };
                        get(`/getUserTonesAndServices/${msisdn}/${config.apiKey}`, undefined, headers) //key: config.baseUrl
                            .then((tonesAndServicesResponse) => {
                                userContextState.setUser({
                                    msisdn: msisdn,
                                    isValid: true,
                                    userTones: tonesAndServicesResponse.userTones,
                                    userServices: tonesAndServicesResponse.userServices
                                });
                        })     
        }
    }, []);

    return (
        <UserContext.Provider value={userContextState}>
            {children}
        </UserContext.Provider>
    )
};

export default UserContext;
