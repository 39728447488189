import React, {useEffect, useState} from "react";
import Carousel from "../common/Carousel/Carousel";
import {RouteComponentProps} from "react-router";
import {get} from "../http/http";
import {CategoryTone, parseCategoryTone} from "../model/Category";
import ToneInfo from "../model/ToneInfo";
import Image from "../common/Image/Image";
import config from "../config/config.json";

type AllCategoryProps = RouteComponentProps<{categoryId: string, categoryName: string}>
function Category(props: AllCategoryProps) {
    const [categoryTones, setCategoryTones] = useState<ToneInfo[]>([]);

    useEffect(() => {
        get(`/categoryTones/${props.match.params.categoryId}/${config.apiKey}`)
            .then((tones: CategoryTone[]) => {
                setCategoryTones(parseCategoryTone(tones));
            });
    }, [props.match.params]);

    return (
        <>
            <div className="row">
                <div className="GrayBar">
                    <div className="GenCont">
                        <Image disablePathUtil id={props.match.params.categoryId} url={config.categoriesHeadersImagesUrl}/>
                            <div className="InternLabel" >
                                <h1>{props.match.params.categoryName}</h1>
                            </div>
                    </div>
                </div>
            </div>
            <Carousel
                removeCarousel
                title={<><b>TONOS</b> DE LA CATEGORÍA</>}
                tones={categoryTones}/>
        </>
    )
}

export default Category
