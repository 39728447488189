import React, {useContext, useEffect} from "react";
import UserContext from "../../context/UserContext";
import AlertContext from "../../context/AlertContext";
import config from "../../config/config.json";
import { get, post } from "../../http/http";

interface FormValues {
    msisdn: string;
    pin: string;
}

const initialValues = {
    msisdn: "",
    pin: "",
} as FormValues;
export default function RegisterModalStep2() {

    const user = useContext(UserContext);
    const alert = useContext(AlertContext);
    const [values, changeValues] = React.useState<FormValues>(initialValues);

    useEffect(() => {
        handleChange("msisdn", user.pinMsisdn);
        $('#msisdn-input').addClass('has-content');
    }, [user.pinMsisdn]);

    const closeModal = () => {
        $('#modal2').removeClass('opened');
    };

    const handleChange = (key: string, value: any) => {
        changeValues((prevState) => {
            return {...prevState, [key]: value}
        })
    };

const generatePass = () => {
        post(`/validatePin`, {pin: values.pin, msisdn: values.msisdn})
            .then((res: any) => {
                if(res.valid) {
                    $('#modal2').removeClass('opened');
                    const headers = {
                        'authorization': `${res.token}`,
                    };
                    get(`/getUserTonesAndServices/${values.msisdn}/${config.apiKey}`, undefined, headers)
                    .then((tonesAndServicesResponse) => {
                          user.setUser({
                            msisdn: Number(values.msisdn),
                            isValid: true,
                            userTones: tonesAndServicesResponse.userTones,
                            userServices: tonesAndServicesResponse.userServices,
                          });
                          sessionStorage.setItem("msisdn", values.msisdn);
                          sessionStorage.setItem("token", res.token);
                    })
                    .catch(() => alert.setMessageAndTitle("Ocurrió un error al Iniciar Sesión", "ERROR"));
                } else {
                    alert.setMessageAndTitle("El pin recibido no es valido", "ERROR")
                    $('#modal2').removeClass('opened');  
                    $("#modal8").addClass("opened");
                }
            }
        )
    .catch(() => alert.setMessageAndTitle("Ocurrió un error al Iniciar Sesión", "ERROR"));
}

    return (
        <div id="modal2" className="modale" aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-header">
                    <h2>Iniciar Sesión Usuario - Paso 2</h2>
                    <button className="btn-close closemodale2" aria-hidden="true" onClick={closeModal}>&times;</button>
                </div>
                <div className="modal-body">
                    <div className="inputCont input-effect">
                        <input className="effect-20"
                               id={"msisdn-input"}
                               type="text"
                               placeholder=""
                               value={values.msisdn}
                               onChange={(e) => handleChange("msisdn", e.target.value)}/>
                            <label>Teléfono</label>
                            <span className="focus-border">
              <i/>
            </span>
                    </div>

                    <div className="inputCont input-effect">
                        <input className="effect-20"
                               type="text"
                               placeholder=""
                               value={values.pin}
                               onChange={(e) => handleChange("pin", e.target.value)}
                        />
                            <label>PIN recibido por SMS</label>
                            <span className="focus-border">
              <i/>
            </span>
                    </div>
                    <p>Usa el PIN enviado a tu celular para continuar con el Registro.</p>

                </div>
                <div className="modal-footer">
                    <button disabled={values.pin.length === 0 || values.msisdn.length === 0} className="btn" id="btn_ingresar" onClick={generatePass}>INICIAR SESIÓN</button>
                    <button className="btn2" id="btn_volver" onClick={closeModal}>VOLVER</button>
                </div>
            </div>
        </div>
    )
}
// .then((res: Transaction & {msisdn: number}) => {