import React from "react";
import "./FAQ.scss";

function FAQ() {
  return (
    <div className="faq ParaphCont">
      <div className="row">
        <div className="titleCont">
          <div className="titlePpal">
            <b>FAQ</b>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="Paraph">
          <h3>¿Qué es un Tono de Espera?</h3>
          <ul>
            <li>
              <p>
                Tonos de Espera Movistar es un servicio que te permite
                personalizar el ring convencional de la llamada telefónica y
                cambiar los segundos de espera de tus amigos por diversión!
                Quien te llame podrá escuchar la música que tú elijas mientras
                te espera.
              </p>
            </li>
          </ul>
        </div>
        <div className="Paraph">
          <h3>¿Cómo activo el servicio?</h3>
          <p>Existen varias opciones para activar tu Tono de Espera:</p>
          <ul>
            <li>
              <p>
                Llamando al *833 y siguiendo los pasos que allí se detallan.
                (Costo de la llamada: primeros 2 minutos gratis y luego $0,03
                IVA incl. x minuto).
              </p>
            </li>
            <li>
              <p>
                Enviando un SMS (sin costo) al 833 con tu artista favorito y
                seleccionar el tema que prefieras.
              </p>
            </li>
            <li>
              <p>Registrándote en esta web y seleccionando el tono.</p>
            </li>
          </ul>
        </div>
        <div className="Paraph">
          <h3>¿Cuánto cuesta el servicio?</h3>
          <ul>
            <li>
              <p>
                El costo es de $73 IVA incl. x mes. Pagando el servicio mensual
                podes activar todos los tonos que quieras.
              </p>
            </li>
          </ul>
        </div>
        <div className="Paraph">
          <h3>
            ¿Cómo puedo encontrar una canción que esté buscando con el SMS
            SEARCH?
          </h3>
          <ul>
            <li>
              <p>
                Podés enviar un SMS (sin costo) al 833 con el tema o artista que
                estás buscando, recibirás un listado de tonos asociados a la
                palabra enviada y si te gusta alguna de las opciones que tenemos
                para vos, enviá el número de la opción que querés activar cómo
                Tono de Espera y listo!
              </p>
            </li>
          </ul>
        </div>
        <div className="Paraph">
          <h3>¿Función STAR TO COPY?</h3>
          <ul>
            <li>
              <p>
                Te gustó el Tono de Espera que escuchaste al llamar a un amigo?
                Podés copiarlo fácilmente, solo tenés que presionar la tecla *
                de tu celular mientras estás escuchando la canción y lo tendrás
                configurado como tu Tono de Espera.
              </p>
            </li>
          </ul>
        </div>
        <div className="Paraph">
          <h3>
            ¿Puedo configurar Tonos de Espera distintos para cada contacto?
          </h3>
          <ul>
            <li>
              <p>
                Es posible configurar un Tono de Espera para que lo escuche
                solamente la persona que tu elijas. Puede ser cualquier número
                móvil o bien un número fijo. Para asignarlo, tenés que elegir un
                tono para un contacto en especial desde esta web.
              </p>
            </li>
          </ul>
        </div>
        <div className="Paraph">
          <h3>¿Cómo creo mis grupos?</h3>
          <ul>
            <li>
              <p>
                Podés agregar los números telefónicos que desees a grupos que
                están predefinidos en esta página web. Para que el grupo quede
                creado debe tener al menos un contacto. Además, para crear tu
                grupo tenés que comprar primero un tono, de lo contrario no se
                podrá generar. Podés cambiar los integrantes de tu grupo, pero
                si eliminas a todos los integrantes perderás el tono asociado al
                grupo.
              </p>
            </li>
            <li>
              <h4>Ejemplo:</h4>
            </li>
            <li>
              <p>
                Tu grupo tiene 1 integrante: Integrante 1 y quieres cambiarlo
                por el Integrante 2. Para que no se te borre tu tono debes
                ingresar al grupo al Integrante 2 primero y luego eliminar al
                Integrante 1.
              </p>
            </li>
          </ul>
        </div>
        <div className="Paraph">
          <h3>¿Cómo puedo desactivar el servicio?</h3>
          <ul>
            <li>
              <p>
                Tenés que enviar un SMS con la palabra BAJA al 833. Recibirás un
                mensaje donde se enumerarán las canciones que tenés compradas
                para que puedas elegir cuál dar de baja. Para bajar alguna
                opción tenés que enviar la letra B el número de opción.
              </p>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default React.memo(FAQ);
