import React, {useEffect, useRef, useState} from "react";
import Slider from "react-slick";
import config from "../config/config.json";
function SliderBanner() {

    const slider = useRef<Slider>(null);

    const [urls, setUrls] = useState<string[]>([]);

    useEffect(() => {
        window.innerWidth < 768 ?
            setUrls(prevUrls => [...prevUrls,
                `${config.bannersImagesUrl}/1-mobile.jpg`,
                `${config.bannersImagesUrl}/2-mobile.jpg`,
                `${config.bannersImagesUrl}/3-mobile.jpg`,
            ])
            :
            setUrls(prevUrls => [...prevUrls,
                `${config.bannersImagesUrl}/1-web.jpg`,
                `${config.bannersImagesUrl}/2-web.jpg`,
                `${config.bannersImagesUrl}/3-web.jpg`,
            ])
    }, []);

    return (
        <div className={"row"}>
            <div className="slideshow-container">
                <Slider fade
                        ref={slider}
                        arrows={false}
                        dots={true}
                        infinite
                        autoplay
                        speed={1000}
                        autoplaySpeed={8000}
                        pauseOnHover
                        adaptiveHeight={true}
                        slidesToShow={1}
                        slidesToScroll={1}
                >
                    {
                        urls.map((url) => (
                            <img key={url}
                                 src={url}
                                 style={{width: "100%"}}
                                 alt={"Banner"}/>
                        ))
                    }
                </Slider>
            </div>
        </div>
    )
}

export default React.memo(SliderBanner)
