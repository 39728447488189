import ToneInfo from "./ToneInfo";

export default interface Category {
    categoryID: number;
    categoryName: string;
    order?: number;
}

export interface CategoryTone {
    albumExtension: string,
    albumId: number,
    toneArtist: string,
    toneId: number,
    toneName: string,
    toneType: string,
    toneUrl: string
}

export function parseCategoryTone(categoryTones: CategoryTone[]): ToneInfo[] {
    let result: ToneInfo[] = [];
    categoryTones.forEach((t) => {
        result.push({
            toneId: t.toneId,
            toneName: t.toneName,
            toneArtist: t.toneArtist,
            toneAlbum: t.albumId.toString(),
            albumExtension: t.albumExtension,
            playing: false
        })
    });
    return result
}
