import React, {useEffect, useState} from "react";
import "./Home.scss"
import {withRouter} from "react-router";
import {get} from "../http/http";
import Carousel from "../common/Carousel/Carousel";
import SliderBanner from "./SliderBanner";
import ToneInfo, {parsePointOfSale} from "../model/ToneInfo";
import PointOfSale from "../model/PointOfSale";
import config from "../config/config.json";


function Home() {

    const [topTones, setTopTones] = useState<ToneInfo[]>([]);
    const [recommendedTones, setRecommendedTones] = useState<ToneInfo[]>([]);

    useEffect( () => {

        get(`/pointofsale/${config.apiKey}`)
            .then((res: {name: string,menus: PointOfSale[]}) => {
                res.menus.forEach((menu: PointOfSale) => {
                    if(menu.name === "TOP10") {
                        setTopTones(parsePointOfSale(menu))
                    } else if(menu.name === "RECOMENDADOS"){
                        setRecommendedTones(parsePointOfSale(menu))
                    }
                })
            });
        return () => {
            setTopTones([]);
            setRecommendedTones([]);
        }
    }, []);

    return (
        <div className={"row"}>
            <SliderBanner />
            <Carousel tones={topTones}
                      customKey={"TOP_TONES_CAROUSEL"}
                      title={<><b>HITS</b> DEL MOMENTO</>}/>
            <Carousel tones={recommendedTones}
                      customKey={"RECOMMENDED_TONES_CAROUSEL"}
                      title={<><b>LOS MAS</b> ACTIVADOS</>}/>
        </div>
    )
}

export default withRouter(Home)
