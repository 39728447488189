import React, {useContext, useState} from "react";
import ToneContext from "../../context/ToneContext";
import {get, post} from "../../http/http";
import UserContext from "../../context/UserContext";
import {Transaction} from "../../model/Transaction";
import AlertContext from "../../context/AlertContext";
import {RouteComponentProps, withRouter} from "react-router";
import config from "../../config/config.json";

type AllPurchaseModalProps = RouteComponentProps;
function PurchaseToneModal(props: AllPurchaseModalProps) {

    const closeModal = (e: any) => {
        $('#modal4').removeClass('opened');
    };

    const [acceptedTerms, setAccepted] = useState<boolean>(false);
    const [confirmTone, setConfirmTone] = useState<boolean>(false);

    const toneContext = useContext(ToneContext);
    const user = useContext(UserContext);
    const alert = useContext(AlertContext);
    const token = sessionStorage.getItem("token");

    const purchaseTone = async (e: any) => {
        e.preventDefault();
        if(acceptedTerms) {
            const headers = {
                'authorization': `${token}`,
            };
            setConfirmTone(true);
            post(`/purchaseTone`, {toneId: toneContext.tone.toneId, msisdn: user.user.msisdn , key: config.apiKey}, headers)
                .then((res: Transaction) => {
                    if (res.transactionResponse && res.transactionResponse.isTransactionOk) {
                        get(`/getUserTonesAndServices/${user.user.msisdn}/${config.apiKey}`, undefined, headers) //key: config.baseUrl
                            .then((tonesAndServicesResponse) => {
                                user.setUser({
                                    ...user.user,
                                    userTones: tonesAndServicesResponse.userTones,
                                    userServices: tonesAndServicesResponse.userServices
                                });
                                $('#modal4').removeClass('opened');  
                                setConfirmTone(false);                              
                            })
                            .catch(() => {
                                alert.setMessageAndTitle("Ocurrió un error al hacer la compras", "ERROR");
                                setConfirmTone(false);     
                            })
                    } else {
                        alert.setMessageAndTitle("Ocurrió un error al hacer la compra", "ERROR");  
                        setConfirmTone(false);                      
                    }
                })
                .catch(() => {
                    alert.setMessageAndTitle("Ocurrió un error al hacer la compra", "ERROR");
                    setConfirmTone(false); 
                });
    
        }
    };

    const changeAccepted = () => {
        setAccepted(!acceptedTerms)
    };

    const termsAndConditionsNavigate =(e: any) => {
        e.preventDefault();
        $('#modal4').removeClass('opened');
        props.history.push("/termsAndConditions")
    };

    return (
        <div id="modal4" className="modale" aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-header">
                    <h2>Confirmación de Compra</h2>
                    <button className="btn-close closemodale4" aria-hidden="true" onClick={closeModal}>&times;</button>
                </div>
                <div className="modal-body center">
                    {
                        toneContext.tone.renewPrice === undefined ?
                            <div className={"loader"}/>
                            :
                            <div className="inputCont">
                                <h2>{`Tono: ${toneContext.tone.toneName}`}</h2>
                                <p>{`Costo del servicio $${toneContext.tone.renewPrice.toFixed(2)} por mes, IVA inc.`}
                                </p>
                            </div>
                    }
                </div>

                <div className="modal-footer">
                    <button disabled={!acceptedTerms || confirmTone} className="btn" id="btn_ingresar" onClick={purchaseTone}>CONFIRMAR</button>
                    <button className="btn2" id="btn_volver" onClick={closeModal}>CANCELAR</button>
                    <div className={"terms-and-conditions"}>
                        <input type={"checkbox"}
                               id={"termsAndConditionsCheckbox"}
                               onClick={changeAccepted}
                               checked={acceptedTerms}/>
                        <small>Acepto <a href={"/"}
                                         onClick={termsAndConditionsNavigate}
                                         className={"navigation"}>Terminos y Condiciones</a></small>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default withRouter(PurchaseToneModal)
