import * as React from "react";
import {useState} from "react";
import ToneInfo from "../model/ToneInfo";

const initialTone = {
    toneId: -1,
    toneArtist: "",
    toneName: "",
    toneAlbum: "",
    playing: false,
    days: undefined,
    purchasePrice: undefined,
    renewPrice: undefined
} as ToneInfo
const initialToneContextState = {
    tone: initialTone,
    setTone: (tone: ToneInfo) => {
    },
    setInitialTone: () => {}
};

const ToneContext = React.createContext(initialToneContextState);

export const ToneProvider: React.FunctionComponent<any> = ({children}) => {
    initialToneContextState.setTone = (tone: ToneInfo) => {
        if (tone == null) {
            updateUserContextState(prevState => {
                return {...prevState, tone: initialToneContextState.tone}
            });
        } else {
            updateUserContextState(prevState => {
                return {...prevState, tone: {...prevState.tone, ...tone}}
            });
        }
    };

    initialToneContextState.setInitialTone = () => {
        updateUserContextState(prevState => {
            return {
                ...prevState, tone: {...initialTone}
            }
        })
    };
    const [userContextState, updateUserContextState] = useState(initialToneContextState);

    return (
        <ToneContext.Provider value={userContextState}>
            {children}
        </ToneContext.Provider>
    )
};

export default ToneContext;
