import * as React from "react"
import {useState} from "react";

export interface AlertContextState {
    title: string;
    message: string;
    setMessageAndTitle: (message: string, title: string) => void;
}
const initialAlertContextState: AlertContextState = {
    title: "",
    message: "",
    setMessageAndTitle: (message: string, title: string) => {},
};

const AlertContext = React.createContext(initialAlertContextState);

export const AlertProvider: React.FunctionComponent<any> = ({children}) => {

    initialAlertContextState.setMessageAndTitle = (message: string, title: string) => {
        $('#alert-modal').addClass('opened');
        updateAudioContextState(prevState => {
            return {...prevState, title, message}
        })
    };

    const [audioContextState, updateAudioContextState] = useState(initialAlertContextState);

    const closeModal = (e: any) => {
        e.preventDefault();
        $('#alert-modal').removeClass('opened');
    };

    return (
        <AlertContext.Provider value={audioContextState}>
            {children}
            <div id="alert-modal" className="modale" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-header">
                        <h2>{audioContextState.title}</h2>
                        <a href="#" className="btn-close closemodale5" aria-hidden="true" onClick={closeModal}>&times;</a>
                    </div>
                    <div className="modal-body">
                        <p>{audioContextState.message}</p>
                    </div>
                    <div className="modal-footer">
                        <a href="#" className="btn2" id="btn_volver" onClick={closeModal}>CANCELAR</a>
                    </div>
                </div>
            </div>
        </AlertContext.Provider>
    )
};


export default AlertContext;
