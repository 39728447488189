import React, {useContext, useEffect} from "react";
import ToneInfo from "../model/ToneInfo";
import {RouteComponentProps} from "react-router";
import {get} from "../http/http";
import UserContext from "../context/UserContext";
import ToneContext from "../context/ToneContext";
import MyAudioContext from "../context/MyAudioContext";
import {playTone, stopTone} from "../utils/AudioUtils";
import Image from "../common/Image/Image";
import config from "../config/config.json";

interface TonePageProps {
}

type AllTonePageProps = TonePageProps & RouteComponentProps<{id: string}>;

function TonePage(props: AllTonePageProps) {

    const user = useContext(UserContext);
    const toneContext = useContext(ToneContext);
    const AudioContext = useContext(MyAudioContext);
    const token = sessionStorage.getItem("token");

    useEffect(() => {
        get(`/tone/${props.match.params.id}/${config.apiKey}`)
            .then((data: ToneInfo & {albumId: string}) => {
                toneContext.setTone({...data, toneAlbum: data.albumId});
            });
        return () => {
            stopTone(AudioContext);
            toneContext.setInitialTone();
        }
    }, []);

    const handlePurchaseTone = (e: any) => {
        e.preventDefault();
        if(user.user.msisdn){
            const headers = {
                'authorization': `${token}`,
            };
            get(`/tone/databuy/${toneContext.tone.toneId}/${user.user.msisdn}/${config.apiKey}`, undefined, headers)
                .then((res) => {
                    if(res.tonePurchasePrice ) {
                        toneContext.setTone({
                            ...toneContext.tone,
                            days: res.tonePurchasePrice.days,
                            purchasePrice: res.tonePurchasePrice.price,
                            renewPrice: res.serviceRenewPrice.price //This breaks with UnlimitedRBT
                        })
                    }
                });
            $('#modal4').addClass('opened');
        } else {
            $('#modal8').addClass('opened');
        }
    };

    return (
        <div className="row">
            <div className="SongCont">
                <div className="CoverCont">
                    <div className="CoverSong">
                        <Image id={toneContext.tone.toneAlbum} extension={toneContext.tone.albumExtension} url={config.imageUrl}/>
                    </div>
                    <div className="SongDecription">
                        <h1>{toneContext.tone.toneName}</h1>
                        <p>{toneContext.tone.toneArtist}</p>
                        <div className="btnCont">
                            {
                                AudioContext.playing ?
                                    AudioContext.loaded ?
                                        <button id={`listener_${toneContext.tone.toneId}`}
                                                className="btnPauseListen"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    stopTone(AudioContext)
                                                }}>
                                            Pausar
                                        </button>
                                        :
                                        <div className={"loader loader-white"}/>
                                    :
                                    <button id={`listener_${toneContext.tone.toneId}`}
                                       className="btnListen"
                                       onClick={(e) => {
                                           e.preventDefault();
                                           playTone(AudioContext, toneContext.tone.toneId)
                                       }}>
                                    Escuchar
                                </button>
                            }
                            {
                                user.user.userTones?.map((ut) => ut.toneId)
                                    .includes(toneContext.tone.toneId)
                                    ? <p>Ya tienes este tono</p>
                                    :
                                    <a href="#" className="btnBuy openmodale4"
                                       onClick={handlePurchaseTone}>
                                        <p>Comprar</p>
                                    </a>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}


export default TonePage
