import React from 'react';
import {Route, Switch, Router, Redirect} from 'react-router'
import AuthRoute from "./common/AuthRoute/AuthRoute";
import Home from "./home/Home";
import {createBrowserHistory} from "history"
import NavBar from "./common/NavBar/NavBar";
import "./css/index.css";
import "./css/owl.carousel.css";
import "./css/slick.css";
import "./css/slick-theme.css";
import "./css/CircleNav.css";
import ArtistAndGenresBar from "./common/ArtistAndGenresBar/ArtistAndGenresBar";
import Footer from "./common/Footer/Footer";
import PromoBanner from "./common/PromoBanner/PromoBanner";
import RegisterModalStep2 from "./common/modals/RegisterModalStep2";
import ToneDeleteModal from "./common/modals/ToneDeleteModal";
import PurchaseToneModal from "./common/modals/PurchaseToneModal";
import RegisterModal from "./common/modals/RegisterModal";
import Profile from "./profile/Profile";
import TonePage from "./tone/TonePage";
import Category from "./category/Category";
import {AlertProvider} from "./context/AlertContext";
import Artist from "./artist/Artist";
import TermsAndConditions from "./termsAndConditions/TermsAndConditions";
import SearchPage from "./search/SearchPage";
import FAQ from "./frequentlyAskedQuestions/FAQ";

function App() {

    const customHistory = createBrowserHistory();

    return (
        <div className="App">
            <Router history={customHistory}>
                <div className={"content"}>
                    <AlertProvider>
                        <RegisterModalStep2/>
                        <ToneDeleteModal/>
                        <PurchaseToneModal/>
                        <RegisterModal/>
                        <NavBar/>
                        <ArtistAndGenresBar/>
                        <Switch>
                            <Route exact path='/home' component={Home}/>
                            <Route exact path={"/tone/:id"} component={TonePage}/>
                            <Route path={"/category/:categoryId/:categoryName"} component={Category}/>
                            <Route exact path={"/artist/:albumId/:artistName"} component={Artist}/>
                            <Route exact path={"/termsAndConditions"} component={TermsAndConditions}/>
                            <Route exact path={"/faq"} component={FAQ}/>
                            <Route exact path={"/search/:searchString"} component={SearchPage}/>
                            <AuthRoute exact path="/profile" component={Profile}/>
                            <Route exact path="*">
                                <Redirect to="/home"/>
                            </Route>
                        </Switch>
                        <Footer/>
                        <PromoBanner/>
                    </AlertProvider>
                </div>
            </Router>
        </div>
    );
}

export default App;
