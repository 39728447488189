import {MyAudioContextState} from "../context/MyAudioContext";
import {pathUtil} from "./FileUtils";
import config from "../config/config.json";

export const stopTone = (AudioContext: MyAudioContextState) => {
    AudioContext.setPlaying(false);
};

export const playTone = (AudioContext: MyAudioContextState, toneId: number) => {
    const basePath = `${config.audioUrl}/${pathUtil(toneId)}.mp3`;
    AudioContext.setUrl(basePath);
    AudioContext.setPlaying(true);
    AudioContext.setCurrentTone(toneId);
};
