import React, {Dispatch, SetStateAction} from "react";
import {post} from "../../../http/http";
import UserContext from "../../../context/UserContext";
import ToneContext from "../../../context/ToneContext";
import AlertContext from "../../../context/AlertContext";
import {TransactionResponse} from "../../../model/Transaction";
import DeleteIcon from '@material-ui/icons/Delete';
import config from "../../../config/config.json";

interface ToneConfigurationCallersProps {
    callers: number[];
    reproductionType: string;
    changeCallers: Dispatch<SetStateAction<number[]>>;
    setAddedNumber: Dispatch<SetStateAction<number>>;
}

function ToneConfigurationCallers({callers, reproductionType, changeCallers, setAddedNumber}: ToneConfigurationCallersProps) {

    const [newMsisdn, setNewMsisdn] = React.useState<string>("");
    const user = React.useContext(UserContext);
    const tone = React.useContext(ToneContext);
    const token = sessionStorage.getItem("token");

    const alert = React.useContext(AlertContext);

    const handleAddClick = () => {
        if(callers.includes(Number(newMsisdn))) {
            alert.setMessageAndTitle("No se puede agregrar el mismo número 2 veces", "Error al agregar un número");
        } else if(newMsisdn === "") {
            alert.setMessageAndTitle("Introduzca un numero para agregar", "Error al agregar un número");
        } else{
            const headers = {
                'authorization': `${token}`,
            };
            post("/callerAssignment", {
                key:config.apiKey,
                msisdn: user.user.msisdn,
                reproductionType,
                callers: [Number(newMsisdn)],
                toneId: tone.tone.toneId,
                operation: "Add"
            }, headers)
                .then((res: TransactionResponse) => {
                    if(res.isTransactionOk){
                        setAddedNumber(prevState => prevState + 1);
                        setNewMsisdn("");
                    } else throw new Error("Transaction not ok")
                })
                .catch(() => {
                    alert.setMessageAndTitle(`Ocurrió un error al agregar el número ${newMsisdn}`, "ERROR");
                })
        }
    };

    const handleRemoveClick = (e: any, callerToRemove: number) => {
        e.preventDefault();
        const headers = {
            'authorization': `${token}`,
        };
        post("/callerAssignment", {
            key:config.apiKey,
            msisdn: user.user.msisdn,
            reproductionType,
            callers: [callerToRemove],
            toneId: tone.tone.toneId,
            operation: "Remove"    
        }, headers)
            .then((res: TransactionResponse) => {
                if(res.isTransactionOk){
                    const newCallers = [...callers].filter((caller) => caller !== callerToRemove);
                    changeCallers(newCallers);
                } else throw new Error("Transaction not ok")
            })
            .catch(() => {
                alert.setMessageAndTitle(`Ocurrió un error al eliminar el número ${callerToRemove}`, "ERROR");
            })
    };

    return (
        <div className={"row ToneCallers"}>
            <div className={"input-and-button-wrapper"}>
                <div className={"input-wrapper inputCont input-effect"}>
                    <input type={"text"}
                           className={"effect-20"}
                           value={newMsisdn}
                           onChange={(e) => {setNewMsisdn(e.target.value)}}/>
                    <label>Teléfono</label>
                    <span className="focus-border">
                            <i/>
                        </span>
                </div>
                <div className={"button-wrapper"}>
                    <button className={"btn"} onClick={handleAddClick}>AGREGAR</button>
                </div>
            </div>
            <small>Al agregar un número, automáticamente se aplica la configuración</small>
            <div className={"callers-wrapper"}>
                {
                    callers.map((caller, index) => (
                        <div className={"caller"}>
                            <p>{caller}</p>
                            <DeleteIcon
                                className={"icon"}
                                onClick={(e) => handleRemoveClick(e, caller)}/>
                        </div>
                    ))
                }
            </div>
        </div>
    )
}

export default ToneConfigurationCallers
