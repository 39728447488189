import React from 'react';
import {Redirect, Route} from 'react-router';

export default function AuthRoute(props: any) {


    let {component: Component, ...rest} = props;

    const msisdn = sessionStorage.getItem("msisdn");
    const token = sessionStorage.getItem("token");
    const authenticated = msisdn && token;
    return (
        <Route {...rest} render={(props: any) => (
            authenticated ? (
                <Component {...props}/>
            ) : (
                <Redirect to={{
                    pathname: '/',
                    state: {from: props.location}
                }}/>
            )
        )}/>
    );
}
