import React from "react";
import {pathUtil} from "../../utils/FileUtils";

interface ImageProps {
    id: any;
    url: string;
    extension?: string;
    disablePathUtil?: boolean;
}

function Image({id, extension, url, disablePathUtil}: ImageProps) {
    return (
        <img key={`image_${id}`} src={`${url}/${disablePathUtil ? id : pathUtil(id)}.${extension || "jpg"}`}
             onError={(e) => {
                 // @ts-ignore
                 e.target.src = `${url}/default.jpg`
             }}
             alt={"IMAGE"}/>
    )
}

export default Image
