import React, {useEffect, useState} from "react";
import Carousel from "../common/Carousel/Carousel";
import {RouteComponentProps, StaticContext, withRouter} from "react-router";
import {get} from "../http/http";
import ToneInfo from "../model/ToneInfo";
import config from "../config/config.json";
import {ArtistTone, parseArtistToneToToneInfo} from "../model/Artist";
import Image from "../common/Image/Image";

type LocationState = {
    albumExtension: string;
}

type AllArtistProps = RouteComponentProps<{albumId: string, artistName: string}, StaticContext, LocationState>
function Artist(props: AllArtistProps) {

    const [artistTone, setArtistTones] = useState<ToneInfo[]>([]);

    useEffect(() => {
        get(`/artistTones/${props.match.params.artistName}/${config.apiKey}`)
            .then((tones: ArtistTone[]) => {
                setArtistTones(parseArtistToneToToneInfo(tones));
            });
    }, [props.match.params]);

    return (
        <>
            <div className="row">
                <div className="ArtistCont">
                    <div className="Artist1">
                        <div className="ArtistCircle">
                            <Image id={props.match.params.albumId} extension={props.location.state.albumExtension} url={config.imageUrl}/>
                        </div>
                    </div>
                    <div className="InternLabel">
                        <h1>{props.match.params.artistName}</h1>
                    </div>
                </div>
            </div>
            <Carousel removeCarousel
                      title={<><b>TONOS</b> DEL ARTISTA</>}
                      tones={artistTone}
                      />
        </>
    )
}

export default withRouter(Artist)
