import React, {useEffect} from "react";
import {get} from "../../http/http";
import UserContext from "../../context/UserContext";
import config from "../../config/config.json";

interface Promotion {
    show: boolean;
    days: number;
}

const initialPromo = {
    show: false,
    days: -1
} as Promotion;

interface PromotionResponse {
    creditsCost: number;
    daysToApply: number;
    promotionType: string;
}
function PromoBanner() {

    const [promotion, setPromotion] = React.useState<Promotion>(initialPromo);

    const user = React.useContext(UserContext);
    useEffect(() => {
        get(`/promotions/${config.apiKey}`)
            .then((res: PromotionResponse[]) => {
                const freePromo = res.find((promo) => promo.promotionType === "ToneTrial");
                if(freePromo) {
                    // @ts-ignore
                    setPromotion(
                        {
                            days: freePromo.daysToApply,
                            show: true
                        });
                }
            })
    }, []);

    const close = (e: any) => {
        e.preventDefault();
        setPromotion((prevState) => {
            return {...prevState, show: false}
        })
    };

    const handleButtonClick = () => {
        if(!user.user.isValid) $('#modal1').addClass("opened");
    };
    
    return (
        promotion.show ?
        <div className="PromoBan">
            <div className="FloatBanLeft">
                <h2 className="FloatH2">{`Te Regalamos ${promotion.days} Días Gratis`}</h2>
                <p className="FloatText">Tus amigos escuchan tu música cuando te llaman</p>
            </div>
            <div className="FloatBanRight">
                <div className="btn" onClick={handleButtonClick}>{`${promotion.days} días gratis`}</div>
            </div>
            <a href="#" className="btn-closeBan" aria-hidden="true" onClick={close}>&times;</a>
        </div> : <div style={{display: "none"}}/>
    )
}

export default PromoBanner
