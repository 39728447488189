import React, {useContext, useEffect, useState} from "react";
import UserContext from "../context/UserContext";
import Carousel from "../common/Carousel/Carousel";
import ToneInfo, {parsePointOfSale} from "../model/ToneInfo";
import {RouteComponentProps, withRouter} from "react-router";
import {get} from "../http/http";
import PointOfSale from "../model/PointOfSale";
import MyAudioContext from "../context/MyAudioContext";
import {playTone, stopTone} from "../utils/AudioUtils";
import ToneContext from "../context/ToneContext";
import {UserTone} from "../model/User";
import ToneConfigurationModal from "../common/modals/ToneConfiguration/ToneConfigurationModal";
import Image from "../common/Image/Image";
import config from "../config/config.json";

interface ProfileProps {
}

type AllProfileProps = ProfileProps & RouteComponentProps;

function Profile(props: AllProfileProps) {

    const user = useContext(UserContext);
    const selectedTone = useContext(ToneContext);
    const AudioContext = useContext(MyAudioContext);
    const [topTones, setTopTones] = useState<ToneInfo[]>([]);
    const [openConfigModal, setOpenConfigModal] = useState<boolean>(false);

    useEffect(() => {
        get(`/pointofsale/${config.apiKey}`)
            .then((res: {name: string,menus: PointOfSale[]}) => {
                res.menus.forEach((menu: PointOfSale) => {
                    if(menu.name === "TOP10") {
                        setTopTones(parsePointOfSale(menu))
                    }
                })
            });
        return () => {
            setTopTones([]);
            stopTone(AudioContext)
        }
    }, []);

    const openModal3 = (ev: any, tone: UserTone) => {
        ev.preventDefault();
        selectedTone.setTone({...tone, toneAlbum: "", playing: false, audioFile: tone.filePath} as ToneInfo);
        $('#modal3').addClass('opened');
    };

    const openModal5 = (ev: any, tone: UserTone) => {
        ev.preventDefault();
        setOpenConfigModal(true);
        selectedTone.setTone({...tone, toneAlbum: "", playing: false, audioFile: tone.filePath} as ToneInfo);
        $('#modal5').addClass('opened');
    };

    return (
        <>
            <ToneConfigurationModal tone={selectedTone.tone}
                                    setOpenConfigModal={setOpenConfigModal}
                                    open={openConfigModal}/>
            <div className="row">
                <div className="titleCont">
                    <div className="titlePpal"><b>Mi</b> Cuenta</div>
                </div>
            </div>
            <div className="row">

                <div className="GrayBar">
                    <div className="AccountLimit">
                        {
                            user.user.userTones?.map((tone: UserTone) => (
                                <div className="AccountCont" key={`user_tone_${tone.toneId}`}>
                                    <div className="AccountBase">
                                        <div className="AccountCol1">
                                            <Image id={tone.albumId} extension={tone.albumExtension} url={config.imageUrl}/>
                                                 <p>Código</p>
                                            <h1>{tone.toneId}</h1>
                                        </div>
                                        <div className="AccountCol2">
                                            <div className="AccountRow1">
                                                <p>Canción</p>
                                                <h1>{tone.toneName}</h1>
                                            </div>
                                            <div className="AccountRow1">
                                                <p>Artista</p>
                                                <h1>{tone.toneArtist}</h1>
                                            </div>
                                            <div className="AccountRow2">
                                                <p>Compra</p>
                                                <h1>{new Date(tone.purchaseDate).toLocaleDateString()}</h1>
                                            </div>
                                            <div className="AccountRow2">
                                                <p>Expiración</p>
                                                <h1>{new Date(tone.expirationDate).toLocaleDateString()}</h1>
                                            </div>
                                            <div className="AccountRow1">
                                                <p>Precio</p>
                                                <h1>{tone.priceRenew}</h1>
                                            </div>
                                        </div>
                                        <div className="settingBar">
                                            {
                                                AudioContext.playing ?
                                                    AudioContext.loaded ?
                                                    <i id={`player_${tone.toneId}`}
                                                       className="btnPause"
                                                       onClick={(e) => {
                                                           e.preventDefault();
                                                           stopTone(AudioContext)
                                                       }}/>
                                                       :
                                                        <div className={"loader profile-loader"}/>
                                                    :
                                                    <i id={`player_${tone.toneId}`}
                                                       className="btnPlay"
                                                       onClick={(e) => {
                                                           e.preventDefault();
                                                           playTone(AudioContext, tone.toneId)
                                                       }}/>
                                            }
                                            <i className="btnSetting openmodale5" onClick={(e) => openModal5(e, tone)}/>
                                            <i className="btnTrash openmodale3" onClick={(e) => openModal3(e, tone)}/>
                                        </div>
                                    </div>
                                </div>

                            ))
                        }
                    </div>
                </div>
            </div>

            <Carousel tones={topTones} title={<><b>HITS</b> DEL MOMENTO</>}/>

        </>
    )
}

export default withRouter(Profile)
