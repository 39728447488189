import PointOfSale from "./PointOfSale";
import ToneInfo from "./ToneInfo";

export interface Artist {
    albumId: number;
    toneArtist: string;
    albumExtension: string;
}

export interface ArtistTone {
    albumId: number;
    toneId: number;
    toneName: string;
    toneArtist: string;
    toneType: string;
    albumExtension: string;
    toneUrl: string;
}

export function parseArtistToneToToneInfo(artistTones: ArtistTone[]): ToneInfo[] {
    let result: ToneInfo[] = [];
    artistTones.forEach((t) => {
        result.push({
            toneId: t.toneId,
            toneName: t.toneName,
            toneArtist: t.toneArtist,
            toneAlbum: t.albumId.toString(),
            albumExtension: t.albumExtension,
            playing: false
        })
    });
    return result
}

export function parsePointOfSaleToArtist(ps: PointOfSale): Artist[] {
    let result: Artist[] = [];

    ps.playList?.forEach((opt) => {
        result.push({
            albumId: opt.albumId,
            toneArtist: opt.toneArtist,
            albumExtension: opt.albumExtension
        })
    });
    return result;
}
