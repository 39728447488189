import React, {useContext} from "react";
import {RouteComponentProps, withRouter} from "react-router";
import UserContext from "../../context/UserContext";
import SearchIcon from '@material-ui/icons/Search';
import {deleteRequest} from "../../http/http";
interface NavBarProps {
}

type AllNavBarProps = NavBarProps & RouteComponentProps;

function NavBar(props: AllNavBarProps) {
    const user = useContext(UserContext);

    const [search, setSearch] = React.useState<string>("");
    const token = sessionStorage.getItem("token");
    const msisdn = sessionStorage.getItem("msisdn");

    const logout = () => {
        
        const headers = {
            'authorization': `${token}`,
            'msisdn':  `${msisdn}`
        };
        deleteRequest(`/delete/token`, undefined, headers)
        sessionStorage.removeItem("msisdn");
        sessionStorage.removeItem("token");
        user.userLogout();
        props.history.push("/home")
    };
    
    const openMenu = () => {
        $("button.arrow").toggleClass("cross");
        $(".menu").slideToggle("");
    };

    const optionClick = (e: any, option: string) => {
        e.preventDefault();

        switch (option) {
            case "OPEN_MODAL_1":
                $('#modal1').addClass('opened');
                break;
            case "OPEN_MODAL_8":
                $('#modal8').addClass('opened');
                break;            
            case "LOGOUT":
                $("opt").toggleClass("cross");
                $(".menu").slideToggle("");
                logout();
                break;
            case "PROFILE":
                $("opt").toggleClass("cross");
                $(".menu").slideToggle("");
                props.history.push("/profile");
                break;
        }
    };

    const handleOnKeyPressSearch = (e: any) => {
        if(e.key === "Enter") {
            setSearch("")
            props.history.push(`/search/${search}`)
        }
    };
    const handleSearchIconClick = () => {
        if(search) {
            props.history.push(`/search/${search}`)
        }
    };

    const moveToHome = () => {
        props.history.push("/home")
    };

    return (
        <nav>
            <div className="navCont">
                <div className="logo" onClick={moveToHome}/>
                <div className={"search-arrow-wrapper"}>
                    <div className={"search-input-container"}>
                        <label htmlFor={"search-input"} className={"search-input-label"}>
                            <SearchIcon className={"search-icon"} onClick={handleSearchIconClick}/>
                        </label>
                        <input className={"search-input"}
                               id={"search-input"}
                               type={"search"}
                               value={search}
                               onKeyPress={handleOnKeyPressSearch}
                               onChange={(e) => setSearch(e.target.value)}
                               placeholder={"Buscar"}/>
                    </div>
                    <button className="arrow" type="button" onClick={(e) => user.user.isValid ? openMenu() : optionClick(e, "OPEN_MODAL_8" )}>                     
                      {
                        user.user.isValid &&
                            <div className="arrowCont">
                                <span className="arrow-hand top"/>
                                <span className="arrow-hand bottom"/>
                            </div>
                      }   
                        <div className="SubsBut">
                            <p>{user.user.msisdn && user.user.isValid ? user.user.msisdn : "Iniciar Sesíon"}</p>
                        </div>
                    </button>
                </div>


                <div className="menu" style={{display: "none"}}>
                    <div className="menuCont">
                        <div className="listCont">
                            <ul>
                                {
                                    user.user.isValid &&
                                        <>
                                            <li className="opt"
                                                onClick={(e) => optionClick(e, "PROFILE")}>
                                                <a href="/">Mi Cuenta</a>
                                            </li>
                                            <li className="opt"
                                                onClick={(e) => optionClick(e, "LOGOUT")}>
                                                <a href="/">Cerrar Sesión</a>
                                            </li>
                                        </>
                                }
                            </ul>
                        </div>
                    </div>
                </div>


            </div>
        </nav>
    )
}

export default withRouter(NavBar)
