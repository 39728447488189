import React from "react";
import {RouteComponentProps, withRouter} from "react-router";
import packageJson from "../../../package.json";
type AllFooterProps = RouteComponentProps;
function Footer(props: AllFooterProps) {

    const termsAndConditionsNavigate = (e: any) => {
        e.preventDefault();
        props.history.push("/termsAndConditions");
    };
    const faqNavigate = (e: any) => {
        e.preventDefault();
        props.history.push("/faq");
    };

    return (
        <footer>
            <div className="footCont">
                <div className="disclaimer">Copyright © 2022 Movistar. All rights reserved.</div>
                <ul>
                    <li><a href="https://www.movistar.com.ar/" target={"_blank"} rel={"noreferrer"}>Movistar</a></li>
                    <li><a href="/termsAndConditions" onClick={termsAndConditionsNavigate} >Términos y Condiciones</a></li>
                    <li><a href="/faq" onClick={faqNavigate} >Preguntas frecuentes</a></li>
                </ul>
            </div>
            <span>v{packageJson.version}</span>
        </footer>
    )
}

export default withRouter(Footer)
