import React from "react";
import "./TermsAndConditions.scss";

function TermsAndConditions() {
  const moveToSection4 = () => {
    const aux = document.getElementById("section_4");
    if (aux) aux.scrollIntoView({ behavior: "smooth" });
  };
  return (
    <div className="tyc ParaphCont">
      <div className="row">
        <div className="titleCont">
          <div className="titlePpal">
            <b>Términos</b> & Condiciones
          </div>
        </div>
      </div>

      <div className="row">
        <div className="Paraph">
          <h3>1. INTRODUCCIÓN</h3>
          <p>
            Los presentes términos y condiciones (Condiciones de Uso) rigen la
            relación entre TELEFONICA MOVILES DEL URUGUAY S.A. (MOVISTAR); sus
            clientes y todos aquellos que interactúan con relación a la
            prestación por parte de MOVISTAR del Servicio de Ringbacktones (en
            adelante, RBT) y la utilización del mismo por parte de los Usuarios.
            Al utilizar o acceder al servicio de RBT, los clientes o usuarios
            manifiestan haber leído las presentes Condiciones de Uso y prestan
            su conformidad y aceptación a las mismas.
          </p>
          <p>
            MOVISTAR se reserva el derecho a modificar en forma unilateral, en
            cualquier momento y sin previo aviso, la presentación, configuración
            y Condiciones de Uso del Servicio. Estas modificaciones regirán a
            partir del momento de publicación de las mismas en la página web
            http://tonosdeespera.movistar.com.ar/ de MOVISTAR. Es
            responsabilidad de los Usuarios verificar la vigencia de la
            presente. El uso continuo y el pago del precio de los servicios
            constituye aceptación de las nuevas Condiciones de Uso.
          </p>
        </div>
        <div className="Paraph">
          <h3>2. Objeto</h3>
          <p>
            MOVISTAR pone a disposición de sus Clientes el servicio de RBT que
            la da la posibilidad a los usuarios de elegir, entre un catálogo de
            opciones disponibles, el tono de espera de sus llamadas.
          </p>
        </div>
        <div className="Paraph">
          <h3>3. El Servicio</h3>
          <p>
            Cualquier cliente Movistar podrá contratar el servicio por los
            siguientes medios:
            <ul>
              <li>
                <p>
                  WEB: ingresando a{" "}
                  <a href={"www.tonosdeespera.movistar.com.ar"}>
                    www.tonosdeespera.movistar.com.ar
                  </a>
                </p>
              </li>
              <li>
                <p>
                  IVR: Llamando al *833 (Costo de la llamada: primer minuto
                  gratis y luego $1 IVA incl. x minuto).
                </p>
              </li>
              <li>
                <p>
                  SMS: Enviando un SMS (sin costo) al 833 con tu artista
                  favorito y seleccionar el tema que prefieras.
                </p>
              </li>
            </ul>
          </p>
          <p>
            Cada tono de espera tiene una suscripción mensual de 30 días,
            contados desde la compra, la cual se renovará automáticamente de
            manera mensual, por iguales períodos, a menos que el cliente
            solicite la baja. El Usuario podrá a través de la página web definir
            diferentes tonos de espera para grupos de personas entre sus
            contactos. En caso de que el Usuario cambie los integrantes de un
            grupo y éste quede vacío, el tono de espera asignado para dicho
            grupo se perderá. Cada grupo, para conservar el tono de espera
            elegido, debe tener al menos un integrante.
          </p>
          <p>
            Para darse de baja del servicio el cliente pude hacerlo enviando un
            SMS con la palabra BAJA al 833.
          </p>
        </div>
        <div id={"section_3"} className="Paraph">
          <h3>4. Precio del Servicio. Pago.</h3>
          <p>
            El costo del Servicio incluye la modalidad AYCE (All You Can Eat) de
            tonos, que consiste en la disponibilidad de un catálogo de tonos de
            espera disponibles para que el Usuario seleccione durante el período
            de suscripción del Servicio. Para aquellos clientes Movistar que
            posean cuenta control dicho costo vendrá en su factura mensual
            separado de la tarifa mensual móvil contratada.
          </p>
          <p>
            Para el caso de los que posean un plan móvil prepago, el importe se
            les descontará de su saldo disponible al momento de la contratación.
            En caso de que el saldo sea insuficiente, se intentará el cobro
            total del saldo durante los 30 días que dura la contratación del
            Servicio. Transcurrido este período, si no pudo ser posible el
            descuento, se cancelará el Servicio.
          </p>
        </div>
        <div className="Paraph">
          <h3>5. Disponibilidad, continuidad, utilidad y falibilidad.</h3>

          <p>
            MOVISTAR no garantiza la disponibilidad y continuidad del
            funcionamiento del Servicio. Cuando ello sea razonablemente posible,
            MOVISTAR advertirá previamente las interrupciones en el
            funcionamiento del Servicio. Como producto de alta tecnología (y con
            el agregado propio de las características técnicas de las
            tecnologías de comunicaciones y en especial las inalámbricas), es
            esencial a la definición del Servicio contratado la comprensión y
            aceptación de que puede estar temporalmente indisponible. MOVISTAR
            no garantiza el servicio ininterrumpido.
          </p>
          <p>
            Es esencial a la definición del Servicio que el mismo pueda no estar
            disponible en condiciones, áreas, o períodos específicos. El Usuario
            entiende que en la operación normal de un servicio de este tipo,
            pueden producirse pérdidas, errores, comunicaciones incompletas,
            fallas, omisiones, interrupciones, demoras, y errores o defectos en
            el servicio de telefonía celular o sus transmisiones.
          </p>
        </div>
        <div className="Paraph">
          <h3>6. Denegación y/o retiro del acceso al servicio.</h3>
          <p>
            MOVISTAR se reserva el derecho a denegar o retirar el acceso al
            Servicio, en cualquier momento y sin necesidad de preaviso a
            aquellos Usuarios que incumplan las presentes Condiciones de Uso
          </p>
        </div>
        <div className="Paraph">
          <h3>7. Duración y Terminación</h3>
          <p>
            La prestación del Servicio tiene, en principio, una duración
            indefinida. MOVISTAR, no obstante, está facultada para dar por
            terminada o suspender la prestación del Servicio en cualquier
            momento comunicándolo de un modo general a los Usuarios con al menos
            quince días de antelación.
          </p>
          <p>
            El Usuario puede dar de baja el servicio en cualquier momento vía
            SMS de la siguiente manera:
            <ul>
              <li>
                <p>
                  SMS: enviando un SMS, sin costo, al 833 con la palabra BAJA.
                </p>
              </li>
            </ul>
          </p>
        </div>
        <div className="Paraph">
          <h3>8. Ley aplicable</h3>
          <p>
            La prestación del Servicio regulado por estas Condiciones de Uso se
            regirá por la legislación de la República Argentina. Para cualquier
            cuestión judicial que pudiera surgir por este Servicio, serán
            competentes los tribunales de la Ciudad Autónoma de Buenos Aires.
          </p>
        </div>
        <div className="Paraph">
          <h3>9. Protección de Datos Personales</h3>
          <p>
            Autorizas a Movistar a recolectar tus datos personales, con la
            finalidad de promover y prestar el servicio, innovarlo y mejorarlo,
            así como la oferta de productos y servicios que mejor se ajusten a
            tus necesidades y hábitos de consumo.
          </p>
          <p>
            Asimismo, autorizas a Movistar y a las empresas del Grupo Telefonica
            a enviarte Publicidad de sus productos y servicios. Al respecto en
            tu carácter de titular de los datos personales podrás solicitar en
            cualquier momento, el retiro o bloqueo, total o parcial de tu nombre
            de los bancos de datos a los que se refiere el artículo 27 inciso 3
            de la Ley de Protección de datos personas N° 25.326 ("LPDP").
            Adicionalmente, podes inscribirte en cualquier momento en el
            registro nacional “No Llame”, a los efectos de manifestar tu
            voluntad de no ser contactado por quien publicite, oferte, venda o
            regale bienes o servicios.
          </p>
          <p>
            Comprendes que Movistar tratara tu información de manera licita,
            para la finalidad mencionada en el presente contrato y con la más
            absoluta confidencialidad, comprometiéndose a guardar secreto
            respecto de los mismos y garantizando el deber de guardarlos y para
            ello adoptará todas las medidas de seguridad necesarias que eviten
            su alteración pérdida y tratamiento o acceso no autorizado, de
            acuerdo con lo establecido en la legislación aplicable.
          </p>
          <p>
            Autorizas a Movistar a compartir tus datos personales con otras
            empresas del Grupo Telefonica en Argentina, con la finalidad de
            obtener de parte de dichas empresas servicios de atención al cliente
            en su representación, y para que dichas empresas del Grupo
            Telefonica en Argentina te envíen propuestas comerciales
            relacionadas con servicios y/o productos prestados por ellas. Las
            empresas del Grupo Telefonica a las que Movistar ceda los datos
            quedarán sujetas a las mismas obligaciones legales establecidas en
            la presente clausula.
          </p>
          <p>
            En tu carácter de titular de los datos personales tenes la facultad
            de ejercer el derecho de acceso a los mismos en forma gratuita a
            intervalos no inferiores a seis (6) meses, salvo que acredite un
            interés legítimo al efecto conforme lo establecido en el artículo
            14, inciso 3 de la ley Nº 25.326.
          </p>
          <p>
            La agencia Nacional de Acceso a la información pública, Órgano de
            control de la ley Nº 25.326, tiene la atribución de atender las
            denuncias y reclamos que se interpongan con relación al
            incumplimiento de las normas sobre protección de datos personales.
          </p>
          <p>
            En tu carácter de titular de los datos personales podrás en
            cualquier momento solicitar el retiro o bloqueo total o parcial de
            mi nombre de los bancos de datos a los que se refiere el artículo
            ART 27 inciso 3 ley 25.326. Podrás realizar los pedidos de retiro o
            bloqueo personalmente en las oficinas comerciales de Movistar, a
            través del *611 o bien mediante el envío de cartas a Av.
            Independencia 169 PB, CP 1109, CABA. Y Movistar procederá a cumplir
            con el pedido dentro de los 5 días hábiles de recibida la
            notificación.
          </p>
        </div>
      </div>
    </div>
  );
}

export default React.memo(TermsAndConditions);
